.calendar-container .calendar-content {
  margin-top: 8px;
  background: #efeff0;
  border-radius: 2px;
  padding: 12px;
}
.calendar-container .calendar-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #404040;
  user-select: none;
  line-height: 1;
}
.calendar-container .calendar-title div {
  width: 40%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}
.calendar-container .calendar-title span {
  font-size: 12px;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.calendar-container .calendar-title .icon {
  font-size: 14px;
}
.calendar-container .weeks-title {
  display: flex;
  align-items: stretch;
  text-align: center;
  font-size: 16px;
  color: #666;
  line-height: 1;
  justify-content: space-between;
}
.calendar-container .weeks-title div {
  flex: 1;
  text-align: center;
}
.calendar-container .days-box {
  margin-top: 8px;
}
.calendar-container .days-box .week-row {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.calendar-container .days-box .day-item {
  width: 28px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.calendar-container .days-box .day-item > div {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #a8a8a8;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  border-radius: 2px;
}
.calendar-container .days-box .day-item:hover > div {
  background: #ddd;
}
.calendar-container .days-box .day-item.active > div {
  background: #404040;
  color: #FFD787;
}
.calendar-container .days-box .day-item.active > div:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  top: 2px;
  left: 2px;
  box-sizing: border-box;
  border: 0.5px solid #FFD787;
  border-radius: 2px;
}
.calendar-container .days-box .day-item.gray > div {
  color: #b8b8b8;
  background: rgba(168, 168, 168, 0.21);
}
.calendar-container .toggle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9a9a9a;
  padding: 10px 0;
  font-size: 12px;
  margin: 0 6px;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid #b8b8b8;
}
.calendar-container .tag-group {
  display: flex;
  align-items: center;
  color: #b8b8b8;
  font-size: 14px;
  position: relative;
}
.calendar-container .tag-group > div {
  padding: 8px 6px;
  cursor: pointer;
  position: relative;
}
.calendar-container .tag-group > div + div:before {
  content: "";
  width: 1px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #d8d8d8;
}
.calendar-container .tag-group > div.active {
  color: #171717;
}
.calendar-container .item-list .item {
  border-radius: 2px;
  background: #f7fafb;
  border-left: 3px solid #8B8B8B;
}
.calendar-container .item-list .item.border-red {
  border-color: #FC6475;
}
.calendar-container .item-list .item.border-green {
  border-color: #4acf75;
}
.calendar-container .item-list .item + .item {
  margin-top: 14px;
}
.calendar-container .item-list .item .info-box {
  height: 60px;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.calendar-container .item-list .item .info-box .tag {
  position: absolute;
  top: 0;
  right: 0;
  background: #797979;
  border-radius: 0 0 0 6px;
  font-size: 12px;
  color: #fff;
  padding: 2px 4px;
}
.calendar-container .item-list .item .info-box .item-title {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 80%;
  font-size: 13px;
  font-weight: bold;
  color: #404040;
  display: block;
}
.calendar-container .item-list .item .info-box .item-title.link:hover {
  text-decoration: underline;
}
.calendar-container .item-list .item .info-box .date-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #424242;
}
.calendar-container .item-list .item .info-box .date-row .item-toggle-btn {
  color: #bbb;
  cursor: pointer;
  user-select: none;
}
.calendar-container .item-list .item .desc-box {
  padding: 8px 12px;
}
.calendar-container .item-list .item .desc-box img {
  width: 100%;
  border-radius: 4px;
}
.calendar-container .item-list .item .desc-box .desc {
  font-size: 12px;
  color: #424242;
}
.calendar-container .item-list .item .count_down {
  margin-top: 10px;
  font-weight: 500;
  color: #404040;
}
.calendar-container .item-list .item .count_down /deep/ span {
  color: #FC6475;
  padding: 0 2px;
}
.ba-calendar-container .days-box .day-item.active > div {
  background: #f4ea4d;
  color: #582a18;
}
.ba-calendar-container .days-box .day-item.active > div:after {
  border-color: #302622;
  opacity: 0.6;
}
.ba-calendar-container .item-list .item {
  background-image: url("~@/assets/ba_imgs/bg-icon1.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 120px auto;
}
.wap-box .days-box .day-item {
  width: 36px;
  height: 36px;
  margin-top: 12px;
}
.wap-box .days-box .day-item.active > div:after {
  width: 32px;
  height: 32px;
}
.wap-box .days-box .day-item > div {
  width: 36px;
  height: 36px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
