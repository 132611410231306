






















































.friend-links {
  display: flex;
  align-items: center;
  border-top: 1px solid #ededed;
  padding: 0 12px;
  height: 48px;
  justify-content: space-between;

  &.scroll-links {
    .link-group {
      width: calc(100% - 30px);
    }

    .left-btn,
    .right-btn {
      display: flex;
    }
  }

  .left-btn,
  .right-btn {
    width: 12px;
    height: 24px;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #323232;
    font-size: 12px;
  }

  .link-group {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  a {
    display: inline-block;

    & + a {
      margin-left: 10px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";