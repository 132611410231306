














































































































































.model-tab-container {
  padding: 0 12px;

  .nav-line {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    .more {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      height: 20px;
      margin-left: 5px;
      padding: 2px 8px;
      color: #999;
      font-size: 12px;
      border-radius: 24px;
      background-color: #f4f4f4;
      cursor: pointer;
    }
  }

  .model-nav {
    flex: 1;
    width: 100%;
    margin-bottom: 8px;
    line-height: 30px;
    white-space: nowrap;
    position: relative;
    user-select: none;
    border-bottom: 1px solid @border-basic;

    &.hasMore {
      width: calc(100% - 60px);
    }
    &.iconPadding {
      padding: 0 30px;
    }

    &.wap-nav {
      .nav-content {
        .scrollbar();

        span {
          margin-right: 30px;
        }
      }
    }

    &.pc-nav {
      .nav-content {
        overflow: hidden;
      }
    }

    .icon-btn {
      width: 24px;
      height: 100%;
      font-size: 18px;
      position: absolute;
      top: 0;
      z-index: 10;
      cursor: pointer;
      background: #fff;
      color: @text-gray;

      &.left-btn {
        left: 0;
      }

      &.right-btn {
        right: 0;
        text-align: right;
      }
    }

    .nav-content {
      width: 100%;
      white-space: nowrap;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    span {
      margin-right: 20px;
      color: @text-gray;
      transition: 0.3s;
      cursor: pointer;
      font-size: 14px;
      display: inline-block;
      position: relative;
      min-width: 30px;
      text-align: center;

      &:hover {
        color: fade(@text-basic, 50%);
      }

      &.active {
        color: #a77334;

        &.line:after {
          content: "";
          background: #be8e54;
          width: 20px;
          height: 2px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 2px;
        }
      }
    }
  }
}

.ba-model-tab-container {
  .model-nav {
    border-bottom: none;
    margin-bottom: 16px;

    span {
      width: auto;
      height: 26px;
      padding: 0 16px;
      line-height: 26px;
      font-size: 12px;
      margin-right: 14px;
      background: url("~@/assets/ba_imgs/item-nav-bg.png") no-repeat center / 100% 100%;

      &.active {
        background: url("~@/assets/ba_imgs/item-nav-bg-active.png") no-repeat center / 100% 100%;
        color: #692b2b;

        &.line:after {
          content: none;
        }
      }
    }
  }
}

.zsca2-model-tab-container {
  padding: 0 3px;
  .nav-line {
    .more {
      color: var(--zs-model-tab-more-text-color);
      background: var(--zs-model-tab-more-bg);
    }
  }
  .model-nav {
    border: none;

    span {
      position: relative;
      display: inline-block;
      min-width: 30px;
      text-align: center;
      transition: 0.3s;
      cursor: pointer;
      font-size: 12px;
      color: var(--zs-model-tab-title-text-color);
      line-height: 16px;
      margin-right: 30px;

      &:hover,
      &.active {
        font-weight: bold;
        font-size: 12px;
        color: var(--zs-model-tab-title-active-color);
        line-height: 16px;
        &.line:after {
          content: none;
        }
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";