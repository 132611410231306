.banner-container {
  width: 100%;
  height: 280px;
  padding-bottom: 20px;
  margin-bottom: 8px;
  border-radius: 6px;
  position: relative;
}
.banner-container .swiper-box {
  height: 100%;
}
.banner-container .banner-item {
  height: 100%;
}
.banner-container .banner-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  background: #fff;
}
.banner-container /deep/ .swiper-slide {
  padding: 0 98px;
}
.banner-container /deep/ .swiper-slide img {
  border: 2px solid #fff;
  height: 260px;
}
.swiper-pagination {
  width: 100%;
  text-align: center;
}
.swiper-pagination /deep/ .swiper-pagination-bullet {
  width: 12px;
  height: 4px;
  background: #9f9f9f;
  border-radius: 3px;
  opacity: 1;
  margin: 0 2px;
}
.swiper-pagination /deep/ .swiper-pagination-bullet-active {
  width: 17px;
  background: #29c0f7;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
