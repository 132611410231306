.user-tag-group {
  display: inline-flex;
  align-items: center;
}
.user-tag-group > div {
  font-size: 10px;
  color: #fff;
  border-radius: 2px;
  height: 15px;
  line-height: 15px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.user-tag-group > div + div {
  margin-left: 5px;
}
.user-tag-group .tag1 {
  background: #da5e5e;
}
.user-tag-group .tag2 {
  background: #666666;
}
.user-tag-group .tag3 {
  background: #999999;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
