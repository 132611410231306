








































































































































































































































































































































































































































































































































































































































































































.pc-home-container {
  // position: relative;
  .wiki-main-box {
    position: relative;
    width: 100%;
    margin-top: 12px;
    padding: 12px;
    border-radius: 12px;
    background: fade(#fff, 76%);
    // background: rgba(174, 181, 203, 0.45);
    .nav-box {
      width: 100%;
      margin-bottom: 12px;
      padding: 0 12px;
      box-sizing: border-box;
    }
  }
  .ba-wiki-main-box {
    margin-top: 0;
    border-radius: 0 0 12px 12px;
    padding: 0;
    .wiki-home {
      padding: 12px;
    }
  }
}

.wiki-home {
  min-height: 500px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;

  .left-container {
    width: calc(100% - 308px);
    border-radius: 2px;
  }

  /deep/ .home-model-container {
    margin-bottom: 8px;
    background: #fff;

    .model-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      margin-bottom: 8px;
      background: #404040;

      .title {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        border-left: 3px solid #f7ce7a;
        margin-left: 5px;
        padding-left: 11px;
        line-height: 20px;
        line-height: 20px;
      }

      .right-wrapper {
        .edit-btn {
          font-size: 12px;
          color: @basic;
        }
      }
    }
  }

  /deep/ .ba-model-container {
    .model-title {
      border-radius: 6px;
      .title {
        color: #3d4667;
        font-size: 16px;
        border-left: 3px solid #ffda35;
        margin-left: 8px;
        padding-left: 8px;
        line-height: 16px;
      }
    }
  }

  .right-container {
    width: 300px;
    display: flex;
    flex-direction: column;
  }
}

.right-model-container {
  width: 100%;
  background: #fff;
  border-radius: 2px;
  margin-bottom: 8px;

  .model-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    background: #404040;

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      border-left: 3px solid #f7ce7a;
      margin-left: 5px;
      padding-left: 11px;
      line-height: 20px;
      line-height: 20px;
    }

    .right-wrapper {
      padding-right: 12px;

      .edit-btn {
        font-size: 12px;
        color: @text-primary;
      }
    }
  }

  .right-model-content {
    padding: 12px 12px 0 12px;
  }
}

.game-detail-box {
  border-top: 6px solid #404040;

  .game-detail-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-top: 8px;
  }

  .game-detail {
    margin: 8px 12px;

    .desc {
      font-size: 13px;
      color: #424242;
      line-height: 20px;
      overflow: hidden;
    }
  }
}
.ba-game-detail-box {
  border-color: #2fceff;
  background-image: url("~@/assets/ba_imgs/bg-icon1.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 150px auto;
}

.ba-right-container {
  .right-model-content {
    background: url("~@/assets/ba_imgs/bg-icon2.png") no-repeat top left / 90px auto;
  }
  .right-model-container .model-title {
    border-radius: 4px;
    .title {
      font-size: 16px;
      border-color: #ffda35;
      padding-left: 8px;
      margin-left: 8px;
    }
    .more-btn,
    .edit-btn {
      color: #666;
    }
  }
  .tag-color {
    transform: skewX(-15deg);
  }
}

.more-btn {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  .wiki-icon-arrow-right {
    display: inline-block;
    width: 5px;
    height: 8px;
    margin-left: 6px;
  }
  a {
    color: @text-basic;
  }
}

.sq-btn {
  color: #292929;
  cursor: pointer;
}

.entry-new-group {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + .item {
      margin-top: 10px;
    }

    &:hover {
      .title {
        text-decoration: underline;
      }
    }

    > div {
      display: flex;
      align-items: center;
    }

    & + a {
      margin-top: 14px;
    }

    .date {
      color: #575757;
      font-size: 12px;
    }

    .title {
      .ellipsis;
      display: block;
      color: #424242;
      font-size: 13px;
      width: 220px;
      line-height: 20px;
    }

    .user {
      .ellipsis;
      width: 70px;
      color: @text-gray;
      font-size: 12px;
      margin-left: 20px;
      text-align: right;
    }
  }
}

.article-nav-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid @border-basic;
  margin-bottom: 20px;

  .nav {
    display: flex;
    align-items: center;

    div + div {
      margin-left: 20px;
    }

    div {
      color: #8b8b8b;
      padding: 4px 0;
      cursor: pointer;

      &.active {
        color: @basic;
      }
    }
  }

  .more-btn {
    color: #575757;
    transition: 0.3s;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      color: @basic;
    }
  }
}
.app-download-card {
  position: relative;
  border-top: 6px solid #404040;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 14px 8px;
  flex-direction: row-reverse;
  gap: 15px;
  .download-qrcode {
    flex-shrink: 0;
    width: 88px;
    height: 88px;
    padding: 4px;
    border: 1px solid #45484c;
    border-radius: 6px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .download-tips {
    flex-shrink: 0;
    width: 183px;
    padding-bottom: 10px;
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #000;
      margin-bottom: 6px;
    }
    .desc {
      font-size: 14px;
      line-height: 20px;
      color: #424242;
    }
  }
  .card-tag {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 4px 0px 4px;
    background-color: rgba(0, 0, 0, 0.08);
    font-size: 12px;
    line-height: 16px;
    color: #515151;
    padding: 0 5px;
    .wiki-icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 3px;
      margin-bottom: 2px;
    }
  }
}

.ba-app-download-card {
  position: relative;
  border-top: none;
  background: url(~@/assets/images/ba-wiki-app-download-card.png) no-repeat;
  background-size: cover;
  background-size: 100%;
  width: 100%;
  height: 103px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  .download-qrcode {
    flex-shrink: 0;
    width: 84px;
    height: 84px;
    padding: 4px;
    border: 2px solid #ffdc24;
    border-radius: 6px;
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .download-tips {
    flex-shrink: 0;
    width: 183px;
    padding-top: 10px;
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #fff;
      margin-bottom: 6px;
    }
    .desc {
      font-size: 12px;
      line-height: 16px;
      color: #fff;
    }
  }
  .card-tag {
    position: absolute;
    top: 2px;
    right: 2px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 4px 4px 4px;
    background-color: #fff;
    font-size: 12px;
    line-height: 16px;
    color: #515151;
    padding: 0 5px;
    .wiki-icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 3px;
      margin-bottom: 2px;
    }
  }
}
.ba-quyun-app-download-card {
  background: url(~@/assets/images/ba-quyun-app-download-card.png) no-repeat;
  background-size: 100%;
}

.link-btn {
  width: 100%;
  height: 56px;
  background: url("~@/assets/images/right-link-btn-default.png") no-repeat center;
  background-size: 100% 100%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 12px;
  font-size: 18px;
  font-weight: bold;
  color: #404040;
  cursor: pointer;

  &.custom-img {
    color: #fff;
    text-shadow: 0 0 2px #999;
  }
}

.question-list {
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  .item {
    position: relative;
    width: 274px;
    height: 36px;
    background: #efefef;
    border-radius: 2px;
    padding: 8px 24px 8px 12px;
    font-size: 14px;
    color: #212121;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .wiki-icon-arrow-right {
      position: absolute;
      top: 12px;
      right: 13px;
      display: inline-block;
      width: 5px;
      height: 8px;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";