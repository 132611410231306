

























































































































































































































































































































































.calendar-container {
  .calendar-content {
    margin-top: 8px;
    background: #efeff0;
    border-radius: 2px;
    padding: 12px;
  }

  .calendar-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: @text-basic;
    user-select: none;
    line-height: 1;

    div {
      width: 40%;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
    }

    span {
      font-size: 12px;
      padding: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .icon {
      font-size: 14px;
    }
  }

  .weeks-title {
    display: flex;
    align-items: stretch;
    text-align: center;
    font-size: 16px;
    color: #666;
    line-height: 1;
    justify-content: space-between;

    div {
      flex: 1;
      text-align: center;
    }
  }

  .days-box {
    margin-top: 8px;

    .week-row {
      display: flex;
      text-align: center;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .day-item {
      width: 28px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;

      > div {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #a8a8a8;
        color: #fff;
        cursor: pointer;
        transition: 0.3s;
        position: relative;
        border-radius: 2px;
      }

      &:hover {
        > div {
          background: #ddd;
        }
      }

      &.active {
        > div {
          background: #404040;
          color: @text-primary;

          &:after {
            content: "";
            position: absolute;
            width: 24px;
            height: 24px;
            top: 2px;
            left: 2px;
            box-sizing: border-box;
            border: 0.5px solid @text-primary;
            border-radius: 2px;
          }
        }
      }

      &.gray {
        > div {
          color: #b8b8b8;
          background: fade(#a8a8a8, 21);
        }
      }
    }
  }

  .toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9a9a9a;
    padding: 10px 0;
    font-size: 12px;
    margin: 0 6px;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid #b8b8b8;
  }

  .tag-group {
    display: flex;
    align-items: center;
    color: #b8b8b8;
    font-size: 14px;
    position: relative;

    > div {
      padding: 8px 6px;
      cursor: pointer;
      position: relative;

      & + div:before {
        content: "";
        width: 1px;
        height: 10px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #d8d8d8;
      }

      &.active {
        color: #171717;
      }
    }
  }

  .item-list {
    .item {
      border-radius: 2px;
      background: #f7fafb;
      border-left: 3px solid @text-gray;

      &.border-red {
        border-color: @red;
      }

      &.border-green {
        border-color: #4acf75;
      }

      & + .item {
        margin-top: 14px;
      }

      .info-box {
        height: 60px;
        padding: 8px 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .tag {
          position: absolute;
          top: 0;
          right: 0;
          background: #797979;
          border-radius: 0 0 0 6px;
          font-size: 12px;
          color: #fff;
          padding: 2px 4px;
        }

        .item-title {
          .ellipsis;
          width: 80%;
          font-size: 13px;
          font-weight: bold;
          color: @text-basic;
          display: block;

          &.link:hover {
            text-decoration: underline;
          }
        }

        .date-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          color: #424242;

          .item-toggle-btn {
            color: #bbb;
            cursor: pointer;
            user-select: none;
          }
        }
      }

      .desc-box {
        padding: 8px 12px;

        img {
          width: 100%;
          border-radius: 4px;
        }

        .desc {
          font-size: 12px;
          color: #424242;
        }
      }

      .count_down {
        margin-top: 10px;
        font-weight: 500;
        color: @text-basic;

        /deep/ span {
          color: @red;
          padding: 0 2px;
        }
      }
    }
  }
}
.ba-calendar-container {
  .days-box .day-item.active {
    > div {
      background: #f4ea4d;
      color: #582a18;
      &:after {
        border-color: #302622;
        opacity: 0.6;
      }
    }
  }
  .item-list .item {
    background-image: url("~@/assets/ba_imgs/bg-icon1.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 120px auto;
  }
}
.wap-box {
  .days-box .day-item {
    width: 36px;
    height: 36px;
    margin-top: 12px;

    &.active > div:after {
      width: 32px;
      height: 32px;
    }

    > div {
      width: 36px;
      height: 36px;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";