.wiki-top-container {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #fff;
}
.wiki-top-container .toggle-btn {
  align-self: flex-end;
  height: 28px;
  padding: 3px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: rgba(1, 1, 1, 0.25);
}
.wiki-top-container .toggle-btn .item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 22px;
  font-weight: 400;
  cursor: pointer;
  color: #fff;
}
.wiki-top-container .toggle-btn .item.current {
  font-weight: 600;
  background-color: #00afe8;
  border-radius: 4px;
}
.wiki-top-container .top-index-content {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 90px;
  background: url("~@/assets/images/wiki-header-bg.png") no-repeat center;
  background-size: 100% 100%;
  padding: 12px;
}
.wiki-top-container .l-wrapper {
  height: 100%;
  display: flex;
}
.wiki-top-container .l-wrapper .game-info {
  display: flex;
  align-items: center;
  margin-right: 16px;
}
.wiki-top-container .l-wrapper .game-info img {
  width: 36px;
  height: 36px;
  border: 1px solid #fff;
  border-radius: 6px;
}
.wiki-top-container .l-wrapper .game-info span {
  font-size: 24px;
  margin-left: 16px;
  color: #fff;
}
.wiki-top-container .l-wrapper .slogan {
  height: 58px;
  margin-right: 10px;
}
.wiki-top-container .r-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.wiki-top-container .r-wrapper .entry-total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  color: #fff;
  font-size: 22px;
  text-shadow: 0px 0px 5px rgba(96, 96, 96, 0.64);
}
.wiki-top-container .r-wrapper .entry-total span {
  font-size: 14px;
  margin-left: 10px;
}
.wiki-top-container .r-wrapper .admin-list {
  display: flex;
  align-items: center;
  color: #fff;
}
.wiki-top-container .r-wrapper .admin-list img {
  width: 28px;
  height: 28px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-right: 16px;
  display: block;
}
.wiki-top-container .r-wrapper .admin-list .plus-btn {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00afe8;
  border-radius: 50%;
  background: #00afe8;
  position: relative;
  cursor: pointer;
}
.wiki-top-container .r-wrapper .admin-list .plus-btn:after {
  content: "";
  width: 12px;
  height: 2px;
  border-radius: 2px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wiki-top-container .r-wrapper .admin-list .plus-btn:before {
  content: "";
  height: 12px;
  width: 2px;
  border-radius: 2px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ba-wiki-top-container {
  background-color: #fff;
  padding: 14px 12px 0 12px;
  border-radius: 0;
}
.ba-wiki-top-container .top-index-content {
  border-radius: 6px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
