.model-tab-container {
  padding: 0 12px;
}
.model-tab-container .nav-line {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}
.model-tab-container .nav-line .more {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  height: 20px;
  margin-left: 5px;
  padding: 2px 8px;
  color: #999;
  font-size: 12px;
  border-radius: 24px;
  background-color: #f4f4f4;
  cursor: pointer;
}
.model-tab-container .model-nav {
  flex: 1;
  width: 100%;
  margin-bottom: 8px;
  line-height: 30px;
  white-space: nowrap;
  position: relative;
  user-select: none;
  border-bottom: 1px solid #f3f3f3;
}
.model-tab-container .model-nav.hasMore {
  width: calc(100% - 60px);
}
.model-tab-container .model-nav.iconPadding {
  padding: 0 30px;
}
.model-tab-container .model-nav.wap-nav .nav-content {
  overflow-x: auto;
  overflow-y: auto;
}
.model-tab-container .model-nav.wap-nav .nav-content::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 2px;
  transition: all ease 0.5s;
}
.model-tab-container .model-nav.wap-nav .nav-content::-webkit-scrollbar-track {
  background: transparent;
  transition: all ease 0.5s;
}
.model-tab-container .model-nav.wap-nav .nav-content::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
  transition: all ease 0.5s;
  position: absolute;
}
.model-tab-container .model-nav.wap-nav .nav-content:hover::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: rgba(0, 0, 0, 0.2);
}
.model-tab-container .model-nav.wap-nav .nav-content:hover::-webkit-scrollbar-track {
  background: transparent;
}
.model-tab-container .model-nav.wap-nav .nav-content:hover::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
  cursor: pointer;
}
.model-tab-container .model-nav.wap-nav .nav-content span {
  margin-right: 30px;
}
.model-tab-container .model-nav.pc-nav .nav-content {
  overflow: hidden;
}
.model-tab-container .model-nav .icon-btn {
  width: 24px;
  height: 100%;
  font-size: 18px;
  position: absolute;
  top: 0;
  z-index: 10;
  cursor: pointer;
  background: #fff;
  color: #8B8B8B;
}
.model-tab-container .model-nav .icon-btn.left-btn {
  left: 0;
}
.model-tab-container .model-nav .icon-btn.right-btn {
  right: 0;
  text-align: right;
}
.model-tab-container .model-nav .nav-content {
  width: 100%;
  white-space: nowrap;
  overflow: auto;
}
.model-tab-container .model-nav .nav-content::-webkit-scrollbar {
  display: none;
}
.model-tab-container .model-nav span {
  margin-right: 20px;
  color: #8B8B8B;
  transition: 0.3s;
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
  position: relative;
  min-width: 30px;
  text-align: center;
}
.model-tab-container .model-nav span:hover {
  color: rgba(64, 64, 64, 0.5);
}
.model-tab-container .model-nav span.active {
  color: #a77334;
}
.model-tab-container .model-nav span.active.line:after {
  content: "";
  background: #be8e54;
  width: 20px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
}
.ba-model-tab-container .model-nav {
  border-bottom: none;
  margin-bottom: 16px;
}
.ba-model-tab-container .model-nav span {
  width: auto;
  height: 26px;
  padding: 0 16px;
  line-height: 26px;
  font-size: 12px;
  margin-right: 14px;
  background: url("~@/assets/ba_imgs/item-nav-bg.png") no-repeat center / 100% 100%;
}
.ba-model-tab-container .model-nav span.active {
  background: url("~@/assets/ba_imgs/item-nav-bg-active.png") no-repeat center / 100% 100%;
  color: #692b2b;
}
.ba-model-tab-container .model-nav span.active.line:after {
  content: none;
}
.zsca2-model-tab-container {
  padding: 0 3px;
}
.zsca2-model-tab-container .nav-line .more {
  color: var(--zs-model-tab-more-text-color);
  background: var(--zs-model-tab-more-bg);
}
.zsca2-model-tab-container .model-nav {
  border: none;
}
.zsca2-model-tab-container .model-nav span {
  position: relative;
  display: inline-block;
  min-width: 30px;
  text-align: center;
  transition: 0.3s;
  cursor: pointer;
  font-size: 12px;
  color: var(--zs-model-tab-title-text-color);
  line-height: 16px;
  margin-right: 30px;
}
.zsca2-model-tab-container .model-nav span:hover,
.zsca2-model-tab-container .model-nav span.active {
  font-weight: bold;
  font-size: 12px;
  color: var(--zs-model-tab-title-active-color);
  line-height: 16px;
}
.zsca2-model-tab-container .model-nav span:hover.line:after,
.zsca2-model-tab-container .model-nav span.active.line:after {
  content: none;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
