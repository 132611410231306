



















































































































.banner-container {
  width: 100%;
  height: 280px;
  padding-bottom: 20px;
  margin-bottom: 8px;
  border-radius: 6px;
  position: relative;

  .swiper-box {
    height: 100%;
  }

  .banner-item {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
      background: #fff;
    }
  }

  /deep/ .swiper-slide {
    padding: 0 98px;

    img {
      border: 2px solid #fff;
      height: 260px;
    }
  }
}

.swiper-pagination {
  width: 100%;
  text-align: center;

  /deep/ .swiper-pagination-bullet {
    width: 12px;
    height: 4px;
    background: #9f9f9f;
    border-radius: 3px;
    opacity: 1;
    margin: 0 2px;
  }

  /deep/ .swiper-pagination-bullet-active {
    width: 17px;
    background: #29c0f7;
  }
}
.ba-banner {
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";