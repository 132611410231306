












































































.top-nav-container {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #404040;
  margin-bottom: 12px;
  &.ba-top-nav-container {
    background: #fff;
    border-bottom: none;
  }
  &.fixed-top {
    .top-nav-body {
      width: 1200px;
      position: fixed;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      background: rgba(255, 255, 255, 0.76);
    }
  }

  .top-nav-body {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .l-wrapper {
    font-size: 14px;
    color: #323232;
    width: calc(100% - 160px);

    span {
      .ellipsis;
      padding: 0 8px;
      color: #323232;
      cursor: pointer;
      display: inline-block;
      max-width: 86px;

      &:hover {
        color: #000;
      }
    }
  }

  .r-wrapper {
    width: 160px;
  }

  .search-box {
    width: 140px;
    height: 28px;
    border-radius: 28px;
    border: 1px solid fade(#555555, 64);
    position: relative;

    input {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      padding-right: 30px;
      padding-left: 10px;
      font-size: 12px;
    }

    .iconfont {
      color: fade(#555555, 64);
      position: absolute;
      right: 6px;
      top: 6px;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";