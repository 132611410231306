





















































































































.wiki-top-container {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #fff;

  .toggle-btn {
    align-self: flex-end;
    height: 28px;
    padding: 3px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background: rgba(1, 1, 1, 0.25);
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 76px;
      height: 22px;
      font-weight: 400;
      cursor: pointer;
      color: #fff;
      &.current {
        font-weight: 600;
        background-color: #00afe8;
        border-radius: 4px;
      }
    }
  }

  .top-index-content {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 90px;
    background: url("~@/assets/images/wiki-header-bg.png") no-repeat center;
    background-size: 100% 100%;
    padding: 12px;
  }

  .l-wrapper {
    // padding: 0 24px;
    height: 100%;
    // width: 450px;
    display: flex;
    // justify-content: space-between;
    // flex-direction: column;
    // align-items: flex-end;
    .game-info {
      display: flex;
      align-items: center;
      margin-right: 16px;

      img {
        width: 36px;
        height: 36px;
        border: 1px solid #fff;
        border-radius: 6px;
      }

      span {
        font-size: 24px;
        margin-left: 16px;
        color: #fff;
      }
    }

    .slogan {
      height: 58px;
      margin-right: 10px;
      // margin-right: 15px;
    }
  }

  .r-wrapper {
    // padding: 0 24px;
    // width: 550px;
    //background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.46) 16%, rgba(0, 0, 0, 0.61) 100%);
    display: flex;
    flex-direction: column;
    // align-items: flex-end;
    justify-content: space-between;

    .entry-total {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 10px;
      // margin-right: 65px;
      color: #fff;
      font-size: 22px;
      text-shadow: 0px 0px 5px rgba(96, 96, 96, 0.64);

      span {
        font-size: 14px;
        margin-left: 10px;
      }
    }

    .admin-list {
      display: flex;
      align-items: center;
      color: #fff;

      img {
        width: 28px;
        height: 28px;
        border: 1px solid #fff;
        border-radius: 50%;
        margin-right: 16px;
        display: block;
      }

      .plus-btn {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #00afe8;
        border-radius: 50%;
        background: #00afe8;
        position: relative;
        cursor: pointer;

        &:after {
          content: "";
          width: 12px;
          height: 2px;
          border-radius: 2px;
          background: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:before {
          content: "";
          height: 12px;
          width: 2px;
          border-radius: 2px;
          background: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
.ba-wiki-top-container {
  background-color: #fff;
  padding: 14px 12px 0 12px;
  border-radius: 0;
  .top-index-content {
    border-radius: 6px;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";