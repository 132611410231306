.ba-model-container .model-title {
  border-radius: 6px;
}
.ba-model-container .model-title .title {
  color: #3d4667;
  font-size: 16px;
  border-left: 3px solid #ffda35;
  margin-left: 4px;
  padding-left: 4px;
  line-height: 16px;
}
.zsca2-model-container {
  margin-bottom: 14px;
}
.zsca2-model-container .model-title {
  display: flex;
  align-items: center;
  height: 34px;
  font-weight: bold;
  font-size: 16px;
  color: var(--zs-server-tab-color);
  line-height: 34px;
  background: var(--zs-server-wrapper-bg) !important;
  margin-bottom: 10px;
  border-radius: 4px;
}
.zsca2-model-container .model-title:before {
  content: "";
  display: block;
  width: 3px;
  height: 16px;
  background: var(--zs-model-title-before);
  margin: 0 7px 0 9px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
