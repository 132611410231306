.pc-home-container .wiki-main-box {
  position: relative;
  width: 100%;
  margin-top: 12px;
  padding: 12px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.76);
}
.pc-home-container .wiki-main-box .nav-box {
  width: 100%;
  margin-bottom: 12px;
  padding: 0 12px;
  box-sizing: border-box;
}
.pc-home-container .ba-wiki-main-box {
  margin-top: 0;
  border-radius: 0 0 12px 12px;
  padding: 0;
}
.pc-home-container .ba-wiki-main-box .wiki-home {
  padding: 12px;
}
.wiki-home {
  min-height: 500px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
}
.wiki-home .left-container {
  width: calc(100% - 308px);
  border-radius: 2px;
}
.wiki-home /deep/ .home-model-container {
  margin-bottom: 8px;
  background: #fff;
}
.wiki-home /deep/ .home-model-container .model-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-bottom: 8px;
  background: #404040;
}
.wiki-home /deep/ .home-model-container .model-title .title {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  border-left: 3px solid #f7ce7a;
  margin-left: 5px;
  padding-left: 11px;
  line-height: 20px;
}
.wiki-home /deep/ .home-model-container .model-title .right-wrapper .edit-btn {
  font-size: 12px;
  color: #00B64F;
}
.wiki-home /deep/ .ba-model-container .model-title {
  border-radius: 6px;
}
.wiki-home /deep/ .ba-model-container .model-title .title {
  color: #3d4667;
  font-size: 16px;
  border-left: 3px solid #ffda35;
  margin-left: 8px;
  padding-left: 8px;
  line-height: 16px;
}
.wiki-home .right-container {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.right-model-container {
  width: 100%;
  background: #fff;
  border-radius: 2px;
  margin-bottom: 8px;
}
.right-model-container .model-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  background: #404040;
}
.right-model-container .model-title .title {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  border-left: 3px solid #f7ce7a;
  margin-left: 5px;
  padding-left: 11px;
  line-height: 20px;
}
.right-model-container .model-title .right-wrapper {
  padding-right: 12px;
}
.right-model-container .model-title .right-wrapper .edit-btn {
  font-size: 12px;
  color: #FFD787;
}
.right-model-container .right-model-content {
  padding: 12px 12px 0 12px;
}
.game-detail-box {
  border-top: 6px solid #404040;
}
.game-detail-box .game-detail-title {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 8px;
}
.game-detail-box .game-detail {
  margin: 8px 12px;
}
.game-detail-box .game-detail .desc {
  font-size: 13px;
  color: #424242;
  line-height: 20px;
  overflow: hidden;
}
.ba-game-detail-box {
  border-color: #2fceff;
  background-image: url("~@/assets/ba_imgs/bg-icon1.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 150px auto;
}
.ba-right-container .right-model-content {
  background: url("~@/assets/ba_imgs/bg-icon2.png") no-repeat top left / 90px auto;
}
.ba-right-container .right-model-container .model-title {
  border-radius: 4px;
}
.ba-right-container .right-model-container .model-title .title {
  font-size: 16px;
  border-color: #ffda35;
  padding-left: 8px;
  margin-left: 8px;
}
.ba-right-container .right-model-container .model-title .more-btn,
.ba-right-container .right-model-container .model-title .edit-btn {
  color: #666;
}
.ba-right-container .tag-color {
  transform: skewX(-15deg);
}
.more-btn {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.more-btn .wiki-icon-arrow-right {
  display: inline-block;
  width: 5px;
  height: 8px;
  margin-left: 6px;
}
.more-btn a {
  color: #404040;
}
.sq-btn {
  color: #292929;
  cursor: pointer;
}
.entry-new-group .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.entry-new-group .item + .item {
  margin-top: 10px;
}
.entry-new-group .item:hover .title {
  text-decoration: underline;
}
.entry-new-group .item > div {
  display: flex;
  align-items: center;
}
.entry-new-group .item + a {
  margin-top: 14px;
}
.entry-new-group .item .date {
  color: #575757;
  font-size: 12px;
}
.entry-new-group .item .title {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  color: #424242;
  font-size: 13px;
  width: 220px;
  line-height: 20px;
}
.entry-new-group .item .user {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 70px;
  color: #8B8B8B;
  font-size: 12px;
  margin-left: 20px;
  text-align: right;
}
.article-nav-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 20px;
}
.article-nav-box .nav {
  display: flex;
  align-items: center;
}
.article-nav-box .nav div + div {
  margin-left: 20px;
}
.article-nav-box .nav div {
  color: #8b8b8b;
  padding: 4px 0;
  cursor: pointer;
}
.article-nav-box .nav div.active {
  color: #00B64F;
}
.article-nav-box .more-btn {
  color: #575757;
  transition: 0.3s;
  font-size: 12px;
  cursor: pointer;
}
.article-nav-box .more-btn:hover {
  color: #00B64F;
}
.app-download-card {
  position: relative;
  border-top: 6px solid #404040;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 14px 8px;
  flex-direction: row-reverse;
  gap: 15px;
}
.app-download-card .download-qrcode {
  flex-shrink: 0;
  width: 88px;
  height: 88px;
  padding: 4px;
  border: 1px solid #45484c;
  border-radius: 6px;
}
.app-download-card .download-qrcode img {
  width: 100%;
  height: 100%;
}
.app-download-card .download-tips {
  flex-shrink: 0;
  width: 183px;
  padding-bottom: 10px;
}
.app-download-card .download-tips .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #000;
  margin-bottom: 6px;
}
.app-download-card .download-tips .desc {
  font-size: 14px;
  line-height: 20px;
  color: #424242;
}
.app-download-card .card-tag {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 4px 0px 4px;
  background-color: rgba(0, 0, 0, 0.08);
  font-size: 12px;
  line-height: 16px;
  color: #515151;
  padding: 0 5px;
}
.app-download-card .card-tag .wiki-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 3px;
  margin-bottom: 2px;
}
.ba-app-download-card {
  position: relative;
  border-top: none;
  background: url(~@/assets/images/ba-wiki-app-download-card.png) no-repeat;
  background-size: cover;
  background-size: 100%;
  width: 100%;
  height: 103px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.ba-app-download-card .download-qrcode {
  flex-shrink: 0;
  width: 84px;
  height: 84px;
  padding: 4px;
  border: 2px solid #ffdc24;
  border-radius: 6px;
  background-color: #fff;
}
.ba-app-download-card .download-qrcode img {
  width: 100%;
  height: 100%;
}
.ba-app-download-card .download-tips {
  flex-shrink: 0;
  width: 183px;
  padding-top: 10px;
}
.ba-app-download-card .download-tips .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #fff;
  margin-bottom: 6px;
}
.ba-app-download-card .download-tips .desc {
  font-size: 12px;
  line-height: 16px;
  color: #fff;
}
.ba-app-download-card .card-tag {
  position: absolute;
  top: 2px;
  right: 2px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 4px 4px 4px;
  background-color: #fff;
  font-size: 12px;
  line-height: 16px;
  color: #515151;
  padding: 0 5px;
}
.ba-app-download-card .card-tag .wiki-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 3px;
  margin-bottom: 2px;
}
.ba-quyun-app-download-card {
  background: url(~@/assets/images/ba-quyun-app-download-card.png) no-repeat;
  background-size: 100%;
}
.link-btn {
  width: 100%;
  height: 56px;
  background: url("~@/assets/images/right-link-btn-default.png") no-repeat center;
  background-size: 100% 100%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 12px;
  font-size: 18px;
  font-weight: bold;
  color: #404040;
  cursor: pointer;
}
.link-btn.custom-img {
  color: #fff;
  text-shadow: 0 0 2px #999;
}
.question-list {
  width: 100%;
  border-bottom: 1px solid #eaeaea;
}
.question-list .item {
  position: relative;
  width: 274px;
  height: 36px;
  background: #efefef;
  border-radius: 2px;
  padding: 8px 24px 8px 12px;
  font-size: 14px;
  color: #212121;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.question-list .item .wiki-icon-arrow-right {
  position: absolute;
  top: 12px;
  right: 13px;
  display: inline-block;
  width: 5px;
  height: 8px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
