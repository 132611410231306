.article-list-item {
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 12px;
  padding-bottom: 12px;
  display: block;
}
.article-list-item .top-user-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.article-list-item .top-user-box img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}
.article-list-item .top-user-box .r-wrapper {
  padding-left: 10px;
  font-size: 12px;
}
.article-list-item .top-user-box .r-wrapper .username {
  max-width: 150px;
  color: #2f2f2f;
  font-weight: 500;
  line-height: 20px;
}
.article-list-item .top-user-box .r-wrapper .date {
  color: #989898;
  font-weight: initial;
  margin-left: 5px;
}
.article-list-item .flex-box {
  display: flex;
}
.article-list-item .flex-box.flex-row {
  justify-content: space-between;
}
.article-list-item .flex-box.flex-row .text-box {
  width: 100%;
}
.article-list-item .flex-box.flex-row.has-img .text-box {
  width: calc(100% - 110px);
}
.article-list-item .flex-box.flex-row .img-box {
  width: 96px;
  height: 60px;
}
.article-list-item .flex-box.flex-row .img-box img {
  width: 96px;
  height: 60px;
  border-radius: 6px;
  object-fit: cover;
}
.article-list-item .flex-box.flex-column {
  flex-direction: column;
}
.article-list-item .flex-box.flex-column .img-box {
  display: flex;
}
.article-list-item .flex-box.flex-column .img-box .item {
  margin-right: 14px;
}
.article-list-item .flex-box.flex-column .img-box .item:last-child {
  margin-right: 0;
}
.article-list-item .flex-box.flex-column .img-box .item img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
@media screen and (max-width: 500px) {
  .article-list-item .flex-box.flex-column .img-box .item {
    width: calc((100% - 14px * 2) / 3);
    height: 90px;
  }
}
@media screen and (min-width: 500px) {
  .article-list-item .flex-box.flex-column .img-box .item {
    width: 140px;
    height: 90px;
    max-width: calc((100% - (14px * 2)) / 3);
  }
}
.article-list-item .flex-box .text-box {
  cursor: pointer;
}
.article-list-item .flex-box .text-box .title {
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: auto;
  font-size: 14px;
  line-height: 24px;
  color: #171717;
}
.article-list-item .flex-box .text-box .title .iconfont {
  font-size: 16px;
  margin-right: 5px;
  color: #e74957;
}
.article-list-item .flex-box .text-box .desc {
  font-size: 12px;
  color: #868686;
  line-height: 18px;
  overflow: hidden;
  max-height: 36px;
}
.article-list-item .bottom-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  color: #575757;
  font-size: 12px;
}
.article-list-item .bottom-box .l-box {
  display: flex;
  align-items: center;
}
.article-list-item .bottom-box .l-box .game-name {
  font-size: 12px;
  color: #666;
  background: #f3f3f3;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 22px;
  margin-right: 14px;
}
.article-list-item .bottom-box .l-box .game-name img {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 6px;
}
.article-list-item .bottom-box .l-box .user {
  display: flex;
  align-items: center;
}
.article-list-item .bottom-box .l-box .user img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 8px;
}
.article-list-item .bottom-box .iconfont {
  margin-right: 4px;
  font-size: 12px;
}
.article-list-item .bottom-box div {
  display: flex;
  align-items: center;
}
.article-list-item .bottom-box .data-box {
  display: flex;
  align-items: center;
}
.article-list-item .bottom-box .data-box > div {
  display: flex;
  align-items: center;
}
.article-list-item .bottom-box .data-box > div + div {
  margin-left: 12px;
  display: flex;
  align-items: center;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
