.friend-link-group {
  display: flex;
  flex-wrap: wrap;
}
.friend-link-group a {
  width: calc((100% - 16px) / 3);
  padding: 3px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  background: #f2f2f2;
  line-height: 1;
}
.friend-link-group a:nth-child(3n) {
  margin-right: 0;
}
.friend-link-group a img {
  width: 18px;
  height: 18px;
}
.friend-link-group a span {
  display: block;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #3a2e25;
  padding: 0 5px;
}
.friend-link-group.ba-friend-link-group a {
  background: #f2f4fa;
  box-shadow: 0px 1px 2px 0px rgba(186, 186, 186, 0.5);
}
@media screen and (max-width: 500px) {
  .friend-link-group a {
    height: 32px;
  }
}
@media screen and (min-width: 500px) {
  .friend-link-group a {
    height: 24px;
  }
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
