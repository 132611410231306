


















































































.ba-model-container {
  .model-title {
    border-radius: 6px;
    .title {
      color: #3d4667;
      font-size: 16px;
      border-left: 3px solid #ffda35;
      margin-left: 4px;
      padding-left: 4px;
      line-height: 16px;
    }
  }
}
.zsca2-model-container {
  margin-bottom: 14px;
  .model-title {
    display: flex;
    align-items: center;
    height: 34px;
    font-weight: bold;
    font-size: 16px;
    color: var(--zs-server-tab-color);
    line-height: 34px;
    background: var(--zs-server-wrapper-bg) !important;
    margin-bottom: 10px;
    border-radius: 4px;
    &:before {
      content: "";
      display: block;
      width: 3px;
      height: 16px;
      background: var(--zs-model-title-before);
      margin: 0 7px 0 9px;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";