





























































.user-tag-group {
  display: inline-flex;
  align-items: center;

  > div {
    font-size: 10px;
    color: #fff;
    border-radius: 2px;
    height: 15px;
    line-height: 15px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    & + div {
      margin-left: 5px;
    }
  }

  .tag1 {
    background: #da5e5e;
  }

  .tag2 {
    background: #666666;
  }

  .tag3 {
    background: #999999;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";