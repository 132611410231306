.top-nav-container {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #404040;
  margin-bottom: 12px;
}
.top-nav-container.ba-top-nav-container {
  background: #fff;
  border-bottom: none;
}
.top-nav-container.fixed-top .top-nav-body {
  width: 1200px;
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background: rgba(255, 255, 255, 0.76);
}
.top-nav-container .top-nav-body {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-nav-container .l-wrapper {
  font-size: 14px;
  color: #323232;
  width: calc(100% - 160px);
}
.top-nav-container .l-wrapper span {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding: 0 8px;
  color: #323232;
  cursor: pointer;
  display: inline-block;
  max-width: 86px;
}
.top-nav-container .l-wrapper span:hover {
  color: #000;
}
.top-nav-container .r-wrapper {
  width: 160px;
}
.top-nav-container .search-box {
  width: 140px;
  height: 28px;
  border-radius: 28px;
  border: 1px solid rgba(85, 85, 85, 0.64);
  position: relative;
}
.top-nav-container .search-box input {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  padding-right: 30px;
  padding-left: 10px;
  font-size: 12px;
}
.top-nav-container .search-box .iconfont {
  color: rgba(85, 85, 85, 0.64);
  position: absolute;
  right: 6px;
  top: 6px;
  font-size: 16px;
  cursor: pointer;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
