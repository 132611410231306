
































































































































.article-list-item {
  border-bottom: 1px solid @border-basic;
  margin-bottom: 12px;
  padding-bottom: 12px;
  display: block;

  .top-user-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    img {
      width: 36px;
      height: 36px;
      object-fit: cover;
      border-radius: 50%;
    }

    .r-wrapper {
      padding-left: 10px;
      font-size: 12px;

      .username {
        max-width: 150px;
        color: #2f2f2f;
        font-weight: 500;
        line-height: 20px;
      }

      .date {
        color: #989898;
        font-weight: initial;
        margin-left: 5px;
      }
    }
  }

  .flex-box {
    display: flex;

    &.flex-row {
      justify-content: space-between;

      .text-box {
        width: 100%;
      }

      &.has-img {
        .text-box {
          width: calc(100% - 110px);
        }
      }

      .img-box {
        width: 96px;
        height: 60px;

        img {
          width: 96px;
          height: 60px;
          border-radius: 6px;
          object-fit: cover;
        }
      }
    }

    &.flex-column {
      flex-direction: column;

      .img-box {
        display: flex;

        .item {
          margin-right: 14px;

          &:last-child {
            margin-right: 0;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
          }
        }

        @media screen and (max-width: 500px) {
          .item {
            width: calc((100% - 14px * 2) / 3);
            height: 90px;
          }
        }

        @media screen and (min-width: 500px) {
          .item {
            width: 140px;
            height: 90px;
            max-width: calc((100% - (14px * 2)) / 3);
          }
        }
      }
    }

    .text-box {
      cursor: pointer;

      .title {
        .ellipsis;
        width: auto;
        font-size: 14px;
        line-height: 24px;
        color: #171717;

        .iconfont {
          font-size: 16px;
          margin-right: 5px;
          color: #e74957;
        }
      }

      .desc {
        font-size: 12px;
        color: #868686;
        line-height: 18px;
        overflow: hidden;
        max-height: 36px;
      }
    }
  }

  .bottom-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    color: #575757;
    font-size: 12px;

    .l-box {
      display: flex;
      align-items: center;

      .game-name {
        font-size: 12px;
        color: #666;
        background: @border-basic;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        height: 22px;
        margin-right: 14px;

        img {
          width: 16px;
          height: 16px;
          border-radius: 2px;
          margin-right: 6px;
        }
      }

      .user {
        display: flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 8px;
        }
      }
    }

    .iconfont {
      margin-right: 4px;
      font-size: 12px;
    }

    div {
      display: flex;
      align-items: center;
    }

    .data-box {
      display: flex;
      align-items: center;

      > div {
        display: flex;
        align-items: center;
      }

      > div + div {
        margin-left: 12px;
        display: flex;
        align-items: center;
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";