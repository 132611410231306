.friend-links {
  display: flex;
  align-items: center;
  border-top: 1px solid #ededed;
  padding: 0 12px;
  height: 48px;
  justify-content: space-between;
}
.friend-links.scroll-links .link-group {
  width: calc(100% - 30px);
}
.friend-links.scroll-links .left-btn,
.friend-links.scroll-links .right-btn {
  display: flex;
}
.friend-links .left-btn,
.friend-links .right-btn {
  width: 12px;
  height: 24px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #323232;
  font-size: 12px;
}
.friend-links .link-group {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.friend-links a {
  display: inline-block;
}
.friend-links a + a {
  margin-left: 10px;
}
.friend-links a img {
  width: 24px;
  height: 24px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
