





































.friend-link-group {
  display: flex;
  flex-wrap: wrap;

  a {
    width: calc((100% - 16px) / 3);
    padding: 3px;
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    background: #f2f2f2;
    line-height: 1;

    &:nth-child(3n) {
      margin-right: 0;
    }

    img {
      width: 18px;
      height: 18px;
    }

    span {
      display: block;
      .ellipsis;
      font-size: 12px;
      color: #3a2e25;
      padding: 0 5px;
    }
  }
  &.ba-friend-link-group {
    a {
      background: #f2f4fa;
      box-shadow: 0px 1px 2px 0px rgba(186, 186, 186, 0.5);
    }
  }

  @media screen and (max-width: 500px) {
    a {
      height: 32px;
    }
  }

  @media screen and (min-width: 500px) {
    a {
      height: 24px;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";